
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { get, patch, post } from "@/api/axios";
import { User } from "@/state/rootState";
@Component
export default class LinkableComponent extends Vue {
    valid: boolean = false;
    isEdit: boolean = false;

    linkableItems: { text: string, value: string }[] = [];

    @Prop({ type: String })
    public id!: string;

    public currentlyValues: { text: string, value: string }[] = [];

    private stillLoading: boolean = false;
    public displayWarning: boolean = false;

    public displayValue: string = "<empty>";

    @Watch('currentlyValues', { deep: true })
    private updateDisplayValue() {
        if (this.currentlyValues)
            this.displayValue = this.currentlyValues.join(',');
    }


    async submitEntity() {
        console.log("submitEntity", this.currentlyValues);

        await get(`/employee/${this.id}/bound`).then(async (x) => {
            console.log(`mounted()::/employee/${this.id}/bound`, x);
            

            if (typeof x.data === "object") {
                this.displayWarning = false;
                const user_id = x.data.user._id;
                await post(`/api/@roles/${user_id}`, this.currentlyValues)
                    .then(x => console.log("Success set roles", x.data));
            } else {
                this.displayWarning = true;
            }
        });

        this.isEdit = false;
    }

    isPropertyEditing(): boolean {
        return this.isEdit;
    }

    cancelEditing() {
        this.isEdit = false;
    }
    updateEntity() { }

    private onAppUserLinkedEvent() {
        console.log("catched event: app-user-link-event")
        get(`/employee/${this.id}/bound`).then(async (x) => {
            console.log(`mounted()::/employee/${this.id}/bound`, x);
            if (typeof x.data === "object") 
                this.displayWarning = false;
            else 
                this.displayWarning = true;
        });
    }

    unmouted() {
        window.removeEventListener("app-user-link-event", this.onAppUserLinkedEvent, false);
    }

    mounted() {
        window.addEventListener("app-user-link-event", this.onAppUserLinkedEvent, false);

        get(`/employee/${this.id}/bound`).then(async (x) => {
            console.log(`mounted()::/employee/${this.id}/bound`, x);


            if (typeof x.data === "object") {
                this.displayWarning = false;
                const user_id = x.data.user._id;
                await get(`/api/@roles/${user_id}`)
                    .then(x => {
                        // TODO
                        console.log("mounted()::/api/@roles/", x.data);
                        this.currentlyValues = x.data;
                    }).catch(console.error);
            } else {
                this.displayWarning = true;
            }
        });

    }

    togleEditProperty() {
        if (this.displayWarning)
            return;

        if (!this.isEdit && this.linkableItems.length == 0) {
            if (this.stillLoading)
                return;
            this.stillLoading = true;
            get(`/api/@roles/`).then(x => {
                this.linkableItems = x.data.map((y: any) => {
                    return { text: y.name, value: y.name };
                });
                this.isEdit = true;
                this.stillLoading = false;
            }).catch(x => {
                console.error(x);
                this.stillLoading = false;
            });
        }
        else
            this.isEdit = !this.isEdit;
    }
}
