<!--
TODO
1. use filter for group for filter items
2. if filtered length is 0, shouldn't show (0)
3. add v-tabs (https://vuetifyjs.com/en/components/tabs) for comfirmed events
3.1 add on tab of comfirmed events timeline view
-->
<template>
  <v-container>
    <!-- Events -->
    <template v-for="(group, groupIndex) in eventsGroups">
      <template v-if="events[group.name].length > 0 || group.name == 'today'">
        <v-row class="pt-4" v-bind:key="groupIndex">
          <h2 class="headline">
            {{ group.displayName || "События" }} ({{
              events[group.name].filter(group.filter).length
            }})
          </h2>
        </v-row>
        <v-row
          class="pt-3"
          v-bind:key="groupIndex"
          v-if="events[group.name].length > 0"
        >
          <v-list width="100%" two-line :color="group.color">
            <template v-for="(item, index) in events[group.name]">
              <!-- подзгололвок -->
              <v-subheader
                v-if="item.header"
                :key="item.header"
                v-text="item.header"
              ></v-subheader>
              <!-- разделитель списка-->
              <v-divider
                v-else-if="item.divider"
                :key="index"
                :inset="item.inset"
              ></v-divider>
              <!-- просмотр события в режиме редактирования -->
              <v-list-item
                v-else-if="selectedEvent == item._id && editable"
                :key="`event-list-item-edit-` + item._id"
              >
                <v-list-item-avatar class="align-top">
                  <v-img
                    src="https://img.pngio.com/promote-png-3-png-image-promote-png-350_371.png"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <event-edit
                    v-bind:event="eventSelected"
                    v-on:new-event-added="updateEvent"
                    v-on:cancel="onCancelEditEvent"
                    submitTitle="Обновить"
                    expanded="true"
                  />
                </v-list-item-content>
              </v-list-item>
              <!-- просмотр события в режиме чтения -->
              <v-list-item
                :key="`event-list-item-view-` + item._id"
                @click="onSelectEvent(item)"
                :color="success"
                v-else-if="item.resolution != 'declined'"
              >
                <v-list-item-avatar>
                  <v-img :src="item.avatar" v-if="item.avatar"></v-img>
                  <v-img
                    src="https://img.pngio.com/promote-png-3-png-image-promote-png-350_371.png"
                    v-else
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ item.date }}: {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-show="showSource">
                      источник
                      {{ item.source || "не указан" }}
                    </span>
                    <span v-if="item.employees && item.employees.length > 0">
                      связано
                      <router-link
                        :to="{
                          name: 'employee',
                          params: { id: item.employees[0]._id },
                        }"
                      >
                        {{ item.employees[0].firstname }}
                        {{ item.employees[0].lastname }}
                      </router-link>
                    </span>
                    <span
                      v-else-if="item.contracts && item.contracts.length > 0"
                    >
                      связано
                      <router-link
                        :to="{
                          name: 'contract',
                          params: { id: item.contracts[0]._id },
                        }"
                      >
                        {{ item.contracts[0].title }}
                      </router-link>
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action
                  :key="item._id"
                  v-if="item.resolution == 'confirmed'"
                >
                  <v-icon style="color: green"
                    >mdi-checkbox-marked-outline</v-icon
                  >
                </v-list-item-action>
                <v-list-item-action
                  :key="item._id + '-confirm'"
                  v-if="!item.resolution"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click.stop="confirmEvent(item._id)"
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-checkbox-marked-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Подтвердить событие</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-action
                  :key="`confirm-event-${item._id}`"
                  v-if="!item.resolution"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click.stop="declineEvent(item._id)"
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-logout-variant</v-icon>
                      </v-btn>
                    </template>
                    <span>Убрать событие</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-row>
        <v-row class="pt-4" v-bind:key="groupIndex" v-else>
          <v-alert
            type="success"
            width="100%"
            v-if="
              group.name == 'today' &&
              events.today.length == 0 &&
              countYesterdayEvents == 0
            "
          >
            На сегодня все события выполнены. Ты молодец!
          </v-alert>
          <v-alert
            type="warning"
            width="100%"
            v-else-if="group.name == 'today' && events.today.length == 0"
          >
            На сегодня все события выполнены, однако есть пропущенные события за
            прошедший период. Ими стоит заняться безотлагательно.
          </v-alert>
        </v-row>
      </template>
    </template>

    <!-- if it's allowed to add new events -->
    <template v-if="editable">
      <v-row class="pb-0 pt-4">
        <!-- <v-container class="px-0 d-flex"> -->
        <h2 class="headline">Новое событие</h2>
        <v-switch
          class="pa-0 pt-1 pl-3 ma-0"
          v-model="plan"
          :label="plan ? 'запланированное событие' : 'плановое событие?'"
        />
        <!-- </v-container> -->

        <!-- <v-tooltip right v-if="editable">
          <template v-slot:activator="{ on, attrs }">


            <v-btn
              class="ml-2"
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              @click.stop="add"
            >
              <v-icon large dark color="primary darken-2">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Запланировать событие</span>
        </v-tooltip> -->
      </v-row>
      <v-row class="pt-0">
        <v-list width="100%">
          <v-list-group
            v-for="item in newEventsType"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  v-text="plan ? item.textPlan : item.textFact"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item>
              <v-list-item-content>
                <event-edit
                  v-bind:event="eventNew"
                  v-on:new-event-added="addEvent"
                  v-on:cancel="item.active = false"
                  v-bind:expanded="addNewEventMode"
                  :hint="plan ? item.hintPlan : item.hintFact"
                  :plan="plan"
                  fieldset="comment"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- <v-list-item-group v-model="selectedNewEventItem" color="primary">
            <v-list-item v-for="(item, i) in newEventsType" :key="i">
              <v-list-item-icon>
                <v-icon color="primary" v-text="item.icon" />
              </v-list-item-icon>
              <v-list-item-content v-if="i != selectedNewEventItem">
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-else>
                <EventEdit
                  v-bind:event="eventNew"
                  v-on:new-event-added="addEvent"
                  v-on:cancel="addNewEventMode = false"
                  v-bind:expanded="addNewEventMode"
                />
              </v-list-item-content>
              <v-list-item-action>
                <v-btn class="ml-2" color="primary" icon @click.stop="add">
                  <v-icon large dark color="primary darken-2">mdi-plus</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group> -->
        </v-list>
      </v-row>
      <v-row class="pt-3" v-if="false">
        <v-list width="100%" two-line>
          <!--  форма для добавления нового события -->
          <v-list-item key="newitem" v-if="selectedEvent == -1">
            <v-list-item-avatar class="align-top">
              <v-img
                src="https://img.pngio.com/promote-png-3-png-image-promote-png-350_371.png"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <EventEdit
                v-bind:event="eventNew"
                v-on:new-event-added="addEvent"
                v-on:cancel="addNewEventMode = false"
                v-bind:expanded="true"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { stringify } from "query-string";
import config from "@/config";
import EventEdit from "@/components/EventEdit.vue";
import { get, post } from "./../api/axios";

const API_URL_EVENTS = process.env.VUE_APP_BASE_API_URL + "/events";
const API_URL_EVENTS_RESOLUTION =
  process.env.VUE_APP_BASE_API_URL + "/event/resolution";

export default {
  name: "event-list",
  props: {
    eventsGroups: {
      type: Array,
      default: () => [
        {
          name: "global",
          displayName: "Без даты",
          color: "blue lighten-5",
          filter: (x) => {
            //return !x.resolution || x.resolution == "confirmed";
            return true || x;
          },
        },
        {
          name: "yesterday",
          displayName: "Вчера",
          color: "red lighten-5",
          filter: (x) => {
            return true || x;
          },
        },
        {
          name: "today",
          displayName: "Сегодня",
          color: "teal lighten-5",
          filter: (x) => {
            return true || x;
          },
        },
        {
          name: "tomorrow",
          displayName: "Завтра",
          color: "lime lighten-5",
          filter: (x) => {
            return true || x;
          },
        },
      ],
    },
    // show confirmed events too
    confirmed: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    showSource: {
      type: Boolean,
      default: false,
    },
    showLink: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    EventEdit,
  },
  data: () => ({
    editable: false,
    showSource: false,
    showLink: true,
    plan: false,
    addNewEventMode: false,
    selectedEvent: -1,
    eventSelected: {
      _id: -1,
      obj: "",
      name: "asdf",
      message: "",
      date: "",
      source: "",
    },
    eventNew: {
      obj: "",
      name: "",
      message: "",
      date: "",
      source: "",
    },
    events: [],
    selectedNewEventItem: 1,
    newEventsType: [
      { textFact: "Проведено собеседование", textPlan: "Провести собеседование", hintFact: "Расскажите, где проходило собеседования, кто принимал участие, какие задавались вопросы?", hintPlan:"dfasdf", icon: "mdi-calendar-today" },
      { textFact: "Принят на работу", textPlan: "Принять на работу", hintFact: "Расскажите, где проходило собеседования, кто принимал участие, какие задавались вопросы?", hintPlan:"dfasdf", icon: "mdi-calendar-today" },
      { textFact: "Получен фидбек по собеседованию", textPlan: "Запросить фидбек по собеседованию", hintFact: "Расскажите, где проходило собеседования, кто принимал участие, какие задавались вопросы?", hintPlan:"dfasdf", icon: "mdi-calendar-today" },
      { textFact: "Ушел в отпуск", textPlan: "Запланировать первый день отпуска", hintFact: "Расскажите, где проходило собеседования, кто принимал участие, какие задавались вопросы?", hintPlan:"dfasdf", icon: "mdi-calendar" },
      { textFact: "Уволен", textPlan: "Запланировать последний день работы по увольнению", hintFact: "Расскажите, где проходило собеседования, кто принимал участие, какие задавались вопросы?", hintPlan:"dfasdf", icon: "mdi-calendar" },
      { textFact: "Плановая веха (задача)", textPlan: "Провести собеседование", hintFact: "Расскажите, где проходило собеседования, кто принимал участие, какие задавались вопросы?", hintPlan:"dfasdf", icon: "mdi-calendar-multiple" },
      { textFact: "Комментарий", textPlan: "Провести собеседование", hintFact: "Расскажите, где проходило собеседования, кто принимал участие, какие задавались вопросы?", hintPlan:"dfasdf", icon: "mdi-comment" },
    ],
  }),
  computed: {
    CONFIG() {
      // eslint-disable-next-line no-console
      console.log("CONFIG: ", config.data());
      return config.data();
    },
    countYesterdayEvents() {
      return this.events["yesterday"] && this.events["yesterday"].length > 0;
    },
  },
  methods: {
    onCancelEditEvent() {
      // eslint-disable-next-line no-console
      console.log(`onCancelEditEvent()`);
      //
      this.selectedEvent = -1;
    },
    onSelectEvent(item) {
      // eslint-disable-next-line no-console
      console.log(`onSelectEvent(${JSON.stringify(item)})`);
      // check
      if (item.resolution != null) {
        // eslint-disable-next-line no-console
        console.log("Event already have solution");
        return;
      }
      // select this event to be edited
      this.eventSelected = item;
      // save selected index
      this.selectedEvent = item._id;
    },
    confirmEvent(id) {
      // eslint-disable-next-line no-console
      console.log(`confirmEvent(${id})`);
      // update on server
      fetch(`${API_URL_EVENTS_RESOLUTION}?id=${id}&value=confirmed`)
        .then((r) => r.json())
        .then((r) => {
          // eslint-disable-next-line no-console
          console.log(r);
          // replace
          //~~~this.replaceEvent(id, r);
          this.reloadEvents();
        });
    },
    reloadEvents() {
      // eslint-disable-next-line no-console
      console.log("loaded events");
      fetch(
        `${API_URL_EVENTS}/display?${stringify(this.$route.params)}&confirmed=${
          this.confirmed
        }`
      )
        .then((resp) => resp.json())
        .then((resp_json) => {
          // eslint-disable-next-line no-console
          console.log(resp_json);
          // ...
          this.events = resp_json;
          // eslint-disable-next-line no-console
          console.log(this.events);
        });
    },
    replaceEvent(id, event) {
      // update collection element
      Object.keys(this.events).forEach(
        (_groupName) =>
          (this.events[_groupName] = this.events[_groupName].map((i) => {
            // eslint-disable-next-line no-console
            console.log(
              "updating item compare id: " +
                i._id +
                " == " +
                id +
                " result is " +
                (i._id == id ? "to be replaced" : "skip")
            );
            if (i._id == id) {
              // eslint-disable-next-line no-console
              console.log("i ", i);
              // eslint-disable-next-line no-console
              console.log("event ", event);
              i = event;
              // eslint-disable-next-line no-console
              console.log("i ", i);
            }
            return i;
            //return i._id == id ? event : i;
          }))
      );
    },
    declineEvent(id) {
      // eslint-disable-next-line no-console
      console.log(`declineEvent(${id})`);
      // update on server
      fetch(`${API_URL_EVENTS_RESOLUTION}?id=${id}&value=declined`)
        .then((r) => r.json())
        .then((r) => {
          // eslint-disable-next-line no-console
          console.log(r);
          // replace
          //~~~this.replaceEvent(id, r);
          this.reloadEvents();
        });
    },
    addEvent(result) {
      console.log(`addEvent(${JSON.stringify(result)})`);
      // todo: add to new event group!
      // add new message on a page
      if (result.details == null) {
        //~~~this.events.push(result);
        this.reloadEvents();
      }
    },
    updateEvent(result) {
      // eslint-disable-next-line no-console
      console.log(`updateEvent(${result})`);
      // update the event in the view, looking up event in each group
      this.reloadEvents();
      /*~~~this.events = this.events.forEach(g => {
        g.map(i => {
          // eslint-disable-next-line no-console
          console.log(i);
          return i._id == result._id ? result : i;
        });
      });*/
      // disbale editing
      this.selectedEvent = -1;
    },
    addEventSubmit() {
      // link to current item
      this.event.obj = this.$route.params.id;
      // eslint-disable-next-line no-console
      console.log(this.event);
      // make post request
      fetch(API_URL_EVENTS, {
        method: "POST",
        body: JSON.stringify(this.event),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          // eslint-disable-next-line no-console
          console.log("response is");
          // eslint-disable-next-line no-console
          console.log(result);
          // add new message on a page
          if (result.details == null) {
            this.events.push(result);
            this.addNewEventMode = 0;
            // clear (shomehow to be cleared TODO)
            this.event.name = " ";
          }
        });
    },
  },
  mounted() {
    this.reloadEvents();
  },
};
</script>
