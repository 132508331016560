
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { get, patch } from "@/api/axios";
import { User } from "@/state/rootState";
@Component
export default class LinkableComponent extends Vue {
  valid: boolean = false;
  isEdit: boolean = false;

  linkableItems: { text: string, value: string }[] = [];


  @Prop({ type: String })
  public module!: string;

  @Prop({ type: String })
  public id!: string;

  public currentlyValue: { text: string, value: string } = { text: "<empty>", value: "" };
  private stillLoading: boolean = false;


  async submitEntity() {
    await patch(`/employee/${this.id}/bound/${this.currentlyValue.value}`, {}).then(x => {
      window.dispatchEvent(new Event("app-user-link-event"));
      this.isEdit = false;
      this.fetchEntity();
    });
  }

  isPropertyEditing(): boolean {
    return this.isEdit;
  }

  cancelEditing() {
    this.isEdit = false;
  }
  updateEntity() { }

  fetchEntity() {
    get(`/${this.module}/${this.id}/bound`)
      .then(x => {
        this.currentlyValue = { text: x.data.user.login, value: x.data.user._id };
      }).catch(console.error);
  }

  mounted() {
    this.fetchEntity();
  }

  togleEditProperty() {
    if (!this.isEdit && this.linkableItems.length == 0) {
      if (this.stillLoading)
        return;
      this.stillLoading = true;
      get<User[]>(`/user`).then(x => {
        this.linkableItems = x.data.map(y => {
          return { text: y.login, value: y._id };
        });
        this.isEdit = true;
        this.stillLoading = false;
      }).catch(x => {
        console.error(x);
        this.stillLoading = false;
      });
    }
    else
      this.isEdit = !this.isEdit;
  }
}
