import * as axios from "axios";
function upload(formData) {
  const url = `${process.env.VUE_APP_BASE_API_URL}/upload`;
  return (
    axios
      .post(url, formData)
      // get data
      .then(x => x.data)
      // add url field
      .then(x =>
        x.map(file =>
          Object.assign({}, file, {
            url: `${process.env.VUE_APP_BASE_API_URL}/upload/${file.id}`
          })
        )
      )
  );
}

export { upload };
