<template>
  <v-container>
    <v-row class="pt-4">
      <h2 class="headline">Файлы</h2>
    </v-row>
    <v-row>
      <v-tabs>
        <v-tab @change="currentFilenames = actualFilenames"
          >Актуальные ({{ actualFilenames.length }})</v-tab
        >
        <v-tab @change="currentFilenames = archivedFilenames"
          >Архив ({{ archivedFilenames.length }})</v-tab
        >
      </v-tabs>
    </v-row>
    <v-row>
      <v-list width="100%">
        <v-list-item
          v-for="(filename, index) in currentFilenames"
          :key="`list-item-file-view-` + index"
          @click="
            download(
              files[filename][0]._id,
              filename,
              `${CONFIG.api.baseUrl}/download/${files[filename][0]._id}`
            )
          "
        >
          <v-list-item-avatar>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="files[filename][0].corrupted ? 'red' : ''"
                  v-bind="attrs"
                  v-on="on"
                  style="cursor: initial"
                  >mdi-file</v-icon
                >
              </template>
              <div v-if="files[filename][0].size">
                Размер файла
                {{ Math.round(files[filename][0].size / 1000, 0) }} Кб
              </div>
              <div v-if="files[filename][0].size && files[filename].length > 1">
                Хранится {{ files[filename].length }} редакции(-ий) этого файла
              </div>
              <div v-if="files[filename][0].corrupted">
                файл временно не доступен
              </div>
            </v-tooltip>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="files[filename][0].originalFilename" />
            <!-- https://www.w3schools.com/howto/howto_js_copy_clipboard.asp -->
          </v-list-item-content>
          <v-list-item-action :key="index">
            <div class="flex">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :href="
                      `${CONFIG.api.baseUrl}/download/${files[filename][0]._id}`
                    "
                    target="_blank"
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Скачать</span>
              </v-tooltip>
              <v-tooltip bottom v-if="files[filename][0].status != 'archived'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click.stop="archive(files[filename][0]._id)"
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-logout-variant</v-icon>
                  </v-btn>
                </template>
                <span>Убрать в архив</span>
              </v-tooltip>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-row>
    <v-row class="pt-3">
      <FileUpload @uploaded="onUploaded" />
    </v-row>
  </v-container>
</template>

<script>
import { stringify } from "query-string";
import config from "@/config";
import FileUpload from "@/components/FileUpload.vue";

const API_URL_FILES = process.env.VUE_APP_BASE_API_URL + "/files";

export default {
  name: "file-box",
  components: {
    FileUpload
  },
  data: () => ({
    files: {},
    currentFilenames: [],
    actualFilenames: [],
    archivedFilenames: []
  }),
  computed: {
    CONFIG() {
      // eslint-disable-next-line no-console
      console.log("CONFIG: ", config.data());
      return config.data();
    }
  },
  methods: {
    onUploaded(filelist) {
      // eslint-disable-next-line no-console
      console.log("onUploaded!", filelist);
      // add new files on a page
      // filelist.map(resp_json => {
      //   ...
      // });
      // just reload files
      this.reloadFiles();
    },
    download(id, filename, url) {
      if (filename.match(/[^\\]*\.pdf$/)) {
        this.$router.push({ name: "document", params: { id: id } });
      } else {
        window.open(url);
      }
    },
    archive(id) {
      this.$http
        .post(API_URL_FILES + "/archive", {
          id: id
        })
        .then(response => {
          this.reloadFiles();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },
    // просто загружает список всех файлов
    reloadFiles() {
      console.log("Loading file list...");
      fetch(`${API_URL_FILES}?${stringify(this.$route.params)}`)
        .then(resp => resp.json())
        .then(resp_json => {
          console.log("Response:", resp_json);
          this.files = resp_json;
          // get filenames with extension from the response
          this.actualFilenames = Object.getOwnPropertyNames(resp_json).filter(
            x =>
              x.match(/[^\\]*\.(\w+)$/) && this.files[x][0].status != "archived"
          );
          this.archivedFilenames = Object.getOwnPropertyNames(resp_json).filter(
            x =>
              x.match(/[^\\]*\.(\w+)$/) && this.files[x][0].status == "archived"
          );
          // set current
          this.currentFilenames = this.actualFilenames;
          console.log("Files:", this.files);
          console.log("Filenames:", this.currentFilenames);
        });
    }
  },
  mounted() {
    this.reloadFiles();
  }
};
</script>
