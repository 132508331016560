<template>
  <v-form v-model="valid" @submit.prevent="addEventSubmit">
    <v-alert color="blue-grey" text icon="mdi-school">
      {{ hint }}
    </v-alert>

    <v-container class="d-flex flex-wrap pa-0 ma-0">
      <v-date-picker
        class="mr-4 mb-4"
        v-model="event.date"
        color="primary lighten-1"
        first-day-of-week="1"
        light="true"
        locale="ru"
        no-title="true"
        v-if="plan"
      ></v-date-picker>
      <v-textarea
        v-if="fieldset == 'comment'"
        clear-icon="mdi-close-circle"
        label="Напиши комментарий"
        auto-grow
        outlined
        rows="3"
        row-height="25"
        :rules="[(v) => !!v || 'Введите комментарий']"
      />
    </v-container>

    <v-text-field
      v-model="event.name"
      label="Название события!"
      @focus="
        () => {
          addNewEventMode = true;
          event.name = event.name.trim();
        }
      "
      required
      v-if="fieldset != 'comment'"
    ></v-text-field>

    <v-container v-if="addNewEventMode">
      <v-row>
        <v-col> </v-col>
        <v-col>
          <v-text-field
            v-model="event.source"
            label="Источник события"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col>
          <v-btn color="primary" :disabled="!valid" type="submit"></v-btn>
        </v-col>
        <v-col>
          <v-btn @click="$emit('cancel')">Отмена</v-btn>
        </v-col>
      </v-row> -->
    </v-container>
    <v-btn class="mr-4" :disabled="!valid" @click="submit">{{
      submitTitle || "Сохранить"
    }}</v-btn>
    <v-btn @click="$emit('cancel')">Отмена</v-btn>
  </v-form>
</template>

<script>
// using:
// this.$route.params.id should contain an object id to witch this event will be referenced

import { stringify } from "query-string";
import moment from "moment";

const API_URL_EVENTS = process.env.VUE_APP_BASE_API_URL + "/events";

export default {
  name: "event-edit",
  // __props: ["event", "submitTitle", "expanded", "fieldset"],
  props: {
    event: {
      type: String,
    },
    submitTitle: {
      type: String,
      default: "Сохранить",
    },
    hint: {
      type: String,
    },
    expanded: {
      type: Boolean,
    },
    fieldset: {
      type: String,
      validator: (value) => {
        return ["comment", "fact", "plan"].indexOf(value) !== -1;
      },
    },
    plan: {
      type: Boolean
    }
  },
  data: () => ({
    addNewEventMode: false,
    selectedEvent: -1,
    valid: false,
  }),
  methods: {
    onCancelEditEvent() {
      //this.event._id = "new";
    },
    addEventSubmit() {
      console.log("addEvent", this.event);
      // link to current employee
      this.event.obj = this.$route.params.id;
      if (!this.event.date) {
        this.event.date = moment(new Date()).format("YYYY-MM-DD");
      }
      // make add request to back
      fetch(API_URL_EVENTS, {
        method: "POST",
        body: JSON.stringify(this.event),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          console.log("response is");
          console.log(result);
          if (result.details == null) {
            this.$emit("new-event-added", result);
            this.addNewEventMode = 0;
            this.event.name = " "; // clear (shomehow to be cleared TODO)
          }
        });
    },
  },
  mounted() {
    if (this.expanded) {
      this.addNewEventMode = true;
    }

    if (this.event != null) {
      // eslint-disable-next-line no-console
      console.log(
        `event-edit: passed ev props ${stringify(
          this.event
        )} that will be used as initial state of event`
      );
      //this.event = this.ev;
    }
    // link this event to an object of parent view by its id
    //this.event.obj = this.$route.params.id;
  },
};
</script>
