<template>
  <v-container>
    <v-row v-if="!employee">
      <v-skeleton-loader
        class="mx-auto"
        max-width="100"
        type="text"
      ></v-skeleton-loader>      
    </v-row>
    <v-row  v-else>
      <h1 class="headline">
        {{ employee.firstname }} {{ employee.lastname }}
        <Status :statusEnumeration="statusEnumeration" entity="employee" />
      </h1>
    </v-row>
    <!-- raiting -->
    <!-- <v-row class="pt-2">
      <v-rating
        :value="4.5"
        color="green"
        dense
        half-increments
        readonly
        size="28"
      ></v-rating>
      <div class="grey--text ml-4">4.5 (413)</div>
    </v-row> -->
    <!-- /raiting -->
    <v-row v-if="!employee">
      <v-progress-linear :buffer-value="bufferValue"></v-progress-linear>
    </v-row>
    <v-row v-else>
      <EntityPropertiesEdit
        :save="update"
        :entity="employee"
        :properties="employeeProperties"
      />
      <Linkable module="employee" :id="employee._id"/>
      <RoleGrantWidget :id="employee._id"/>
      <FileBox />
      <!-- События и задачи -->
      <EventList :eventsGroups="eventsGroups" :editable="true" :done="true" />
    </v-row>
  </v-container>
</template>

<script>
import { stringify } from "query-string";
import config from "@/config";
import Status from "@/components/Status.vue";
import FileBox from "@/components/FileBox.vue";
import EventList from "@/components/EventsList.vue";
import EntityPropertiesEdit from "@/components/EntityPropertiesEdit.vue";
import Linkable from "@/components/Linkable.vue";
import RoleGrantWidget from "../components/RoleGrantWidget.vue";

const API_URL = process.env.VUE_APP_BASE_API_URL + "/employee";
const API_URL_EVENTS = process.env.VUE_APP_BASE_API_URL + "/events";
const API_URL_EVENTS_RESOLUTION =
  process.env.VUE_APP_BASE_API_URL + "/event/resolution";

export default {
  name: "employee",
  components: {
    Status,
    FileBox,
    EventList,
    EntityPropertiesEdit,
    Linkable,
    RoleGrantWidget
},
  data: () => ({
    addNewEventMode: false,
    selectedEvent: -1,
    eventsGroups: [
      {
        name: "confirmed",
        displayName: "Пройденный путь",
        color: "green lighten-5",
        filter: (x) => {
          return true || x;
        },
      },
      {
        name: "global",
        displayName: "Без даты",
        color: "blue lighten-5",
        filter: (x) => {
          return true || x;
        },
      },
      {
        name: "yesterday",
        displayName: "Вчера",
        color: "red lighten-5",
        filter: (x) => {
          return true || x;
        },
      },
      {
        name: "today",
        displayName: "Сегодня",
        color: "teal lighten-5",
        filter: (x) => {
          return true || x;
        },
      },
      {
        name: "tomorrow",
        displayName: "Завтра",
        color: "lime lighten-5",
        filter: (x) => {
          return true || x;
        },
      },
    ],
    statusEnumeration: [
      {
        name: "newbie",
        display: "Новый",
        color: "dark blue blue--text text--darken-1",
      },
      {
        name: "onboard",
        display: "На лавке",
        color: "dark green green--text text--darken-1",
      },
      {
        name: "sick",
        display: "Больничный",
        color: "dark grey grey--text text--darken-1",
      },
      {
        name: "vacation",
        display: "В отпуске",
        color: "dark grey grey--text text--darken-1",
      },
      {
        name: "fired",
        display: "Уволен",
        color: "dark red red--text text--darken-1",
      },
      {
        name: "onduty",
        display: "Работает",
        color: "dark green green--text text--darken-1",
      },
      {
        name: "reserve",
        display: "В резерве",
        color: "dark grey grey--text text--darken-1",
      },
    ],
    eventSelected: {
      _id: -1,
      obj: "",
      name: "asdf",
      message: "",
      date: "",
      source: "",
    },
    eventNew: {
      obj: "",
      name: "",
      message: "",
      date: "",
      source: "",
    },
    events: [],
    employeeProperties: [
      {
        icon: "mdi-domain",
        caption: "Название организации заказчика",
        placeholder: "Организация заказчика не указана",
        name: "customer",
        type: "string",
        hint: "hint",
        message: "Введите название организации заказчика",
      },
      {
        icon: "mdi-briefcase",
        caption: "Название проекта",
        placeholder: "Проект не указана",
        name: "project",
        type: "string",
        hint: "hint",
        message: "Введите название проекта",
      },
      {
        icon: "mdi-map-marker",
        caption: "Локация высадки сотрудника",
        placeholder: "Локация не указана",
        name: "location ",
        type: "string",
        hint: "hint",
        message: "Введите локацию высадки сотрудник",
      },
      {
        icon: "mdi-account",
        caption: "Позиция сотруднкиа на проекте",
        placeholder: "Позиция сотрудника на проекте не определена",
        name: "position ",
        type: "string",
        hint: "hint",
        message: "Введите позицию сотрудника на проекте",
      },
      {
        icon: "mdi-cash",
        caption: "Ставка сотруднка для заказчика на проекте (в руб)",
        placeholder: "0",
        name: "customerrate ",
        type: "currency",
        hint: "hint",
        message: "Введите ставку сотрудника для заказчика на проекте (в руб)",
      },
    ],
    employee: null,
    employeeEditing: {
      customer: false,
      project: false,
      location: false,
      position: false,
      customerrate: false,
    },
  }),
  computed: {
    CONFIG() {
      // eslint-disable-next-line no-console
      console.log("CONFIG: ", config.data());
      return config.data();
    },
  },
  methods: {
    onCancelEditEvent() {
      // eslint-disable-next-line no-console
      console.log(`onCancelEditEvent()`);
      //
      this.selectedEvent = -1;
    },
    onSelectEvent(item) {
      // eslint-disable-next-line no-console
      console.log(`onSelectEvent(${JSON.stringify(item)})`);
      // check
      if (item.resolution != null) {
        // eslint-disable-next-line no-console
        console.log("Event already have solution");
        return;
      }
      // select this event to be edited
      this.eventSelected = item;
      // save selected index
      this.selectedEvent = item._id;
    },
    confirmEvent(id) {
      // eslint-disable-next-line no-console
      console.log(`confirmEvent(${id})`);
      // update on server
      fetch(`${API_URL_EVENTS_RESOLUTION}?id=${id}&value=confirmed`)
        .then((r) => r.json())
        .then((r) => {
          // eslint-disable-next-line no-console
          console.log(r);
          // update collection element
          this.events = this.events.map((i) => {
            // eslint-disable-next-line no-console
            console.log(i);
            return i._id == id ? r : i;
          });
        });
    },
    declineEvent(id) {
      // eslint-disable-next-line no-console
      console.log(`declineEvent(${id})`);
      // update on server
      fetch(`${API_URL_EVENTS_RESOLUTION}?id=${id}&value=declined`)
        .then((r) => r.json())
        .then((r) => {
          // eslint-disable-next-line no-console
          console.log(r);
          // update collection element
          this.events = this.events.map((i) => {
            // eslint-disable-next-line no-console
            console.log(i);
            return i._id == id ? r : i;
          });
        });
    },
    addEvent(result) {
      // eslint-disable-next-line no-console
      console.log(`addEvent(${JSON.stringify(result)})`);
      // add new message on a page
      if (result.details == null) {
        this.events.push(result);
      }
    },
    updateEvent(result) {
      // eslint-disable-next-line no-console
      console.log(`updateEvent(${result})`);
      // update the event in the view
      this.events = this.events.map((i) => {
        // eslint-disable-next-line no-console
        console.log(i);
        return i._id == result._id ? result : i;
      });
      // disbale editing
      this.selectedEvent = -1;
    },
    addEventSubmit() {
      // link to current employee
      this.event.obj = this.$route.params.id;
      // eslint-disable-next-line no-console
      console.log(this.event);
      // make post request
      fetch(API_URL_EVENTS, {
        method: "POST",
        body: JSON.stringify(this.event),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          // eslint-disable-next-line no-console
          console.log("response is");
          // eslint-disable-next-line no-console
          console.log(result);
          // add new message on a page
          if (result.details == null) {
            this.events.push(result);
            this.addNewEventMode = 0;
            // clear (shomehow to be cleared TODO)
            this.event.name = " ";
          }
        });
    },
    update(employee) {
      // eslint-disable-next-line no-console
      console.log(`update(${JSON.stringify(employee)})`);
      // make post request
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(employee),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          // eslint-disable-next-line no-console
          console.log("response is ");
          // eslint-disable-next-line no-console
          console.log(result);
          // add new message on a page
          if (result.details == null) {
            // update employee view
            this.employee = result;
          }
        });
    },
    updateEmployee() {
      // eslint-disable-next-line no-console
      console.log(`updateEmployee(${JSON.stringify(this.employee)})`);
      // make post request
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(this.employee),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          // eslint-disable-next-line no-console
          console.log("response is ");
          // eslint-disable-next-line no-console
          console.log(result);
          // add new message on a page
          if (result.details == null) {
            // update employee view
            this.employee = result;
            // finish editing
            this.employeeEditing.customer = false;
            this.employeeEditing.project = false;
            this.employeeEditing.location = false;
            this.employeeEditing.position = false;
            this.employeeEditing.customerrate = false;
          }
        });
    },
  },

  mounted() {
    // eslint-disable-next-line no-console
    console.log(this.$route.params.id);
    fetch(`${API_URL}?${stringify(this.$route.params)}`)
      .then((resp) => resp.json())
      .then((resp_json) => {
        // eslint-disable-next-line no-console
        console.log(resp_json);

        this.employee = resp_json;
      });

    // eslint-disable-next-line no-console
    console.log("loaded events");
    fetch(`${API_URL_EVENTS}?${stringify(this.$route.params)}`)
      .then((resp) => resp.json())
      .then((resp_json) => {
        // eslint-disable-next-line no-console
        console.log(resp_json);

        this.events = resp_json;
      });
  },
};
</script>
