<template>
  <div class="container">
    <!-- upload form -->
    <form
      enctype="multipart/form-data"
      novalidate
      v-if="isInitial || isSaving || isSuccess"
    >
      <div class="dropbox">
        <!-- takes all files to be uploaded -->
        <input
          type="file"
          multiple
          :name="uploadFieldName"
          :disabled="isSaving"
          @change="
            filesChange($event.target.name, $event.target.files);
            fileCount = $event.target.files.length;
          "
          accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf, image/*"
          class="input-file"
        />
        <p v-if="isInitial || isSuccess">
          Перетащите сюда один или несколько файлов (pdf, docx, excel, txt,
          сканы)<br />для их загрузки под карточку...
        </p>
        <p v-if="isSaving">Загружается {{ fileCount }} файл(-ов)...</p>
      </div>
    </form>
    <!--SUCCESS-->
    <!--div v-if="isSuccess">
      <h2>Загружено {{ uploadedFiles.length }} файл(-ов) успешно.</h2>
      <p>
        <a href="javascript:void(0)" @click="reset()"
          >Загрузить еще немного файлов</a
        >
      </p>
      <ul class="list-unstyled">
        <li v-for="(item, index) in uploadedFiles" :key="index">
          <img
            :src="item.url"
            class="img-responsive img-thumbnail"
            :alt="item.originalName"
          />
        </li>
      </ul>
    </div-->
    <!--FAILED-->
    <div v-if="isFailed">
      <h2>Загрузка файлов не удалась!</h2>
      <p>
        <a href="javascript:void(0)" @click="reset()">Попробуйте еще раз</a>
      </p>
      <pre>{{ uploadError }}</pre>
    </div>
  </div>
</template>

<script>
// swap as you need
import { upload } from "@/api/upload.api";
//import { wait } from "@/utils/utils";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;
export default {
  name: "file-upload",
  props: {
    // event handler on uploaded files
    onUploaded: {
      type: Function
    }
  },
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "file"
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;
      // ?
      upload(formData)
        //.then(wait(1500)) // DEV ONLY: wait for 1.5s
        .then(x => {
          this.uploadedFiles = [].concat(x);
          this.currentStatus = STATUS_SUCCESS;
          // eslint-disable-next-line no-console
          this.$emit("uploaded", [].concat(x)); // fire an event
        })
        .catch(err => {
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();
      if (!fileList.length) return;
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map(x => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });
      // add owner id
      // eslint-disable-next-line no-console
      console.log("asdfasdfasdf");
      formData.append("obj", this.$route.params.id);
      // eslint-disable-next-line no-console
      console.log("asdfasdfasdf!");
      // save it
      this.save(formData);
    }
  },
  mounted() {
    this.reset();
  }
};
</script>

<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
